<template>
    <page-title
        title="社員編集"
        icon="bi-person-fill"
    >
        <button-back
            :to="{name: 'EmployeeList'}"
            text="社員一覧へ"
        ></button-back>
    </page-title>

    <section class="section">
        <form @submit.prevent="save">
            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label>氏名</label>
                    <form-input
                        v-model="employee.photographer_name"
                        :required="true"
                    ></form-input>
                </div>
                <div class="form-group col-md-3">
                    <label>略称(一文字)</label>
                    <form-input
                        v-model="employee.shortened_name"
                        :required="true"
                    ></form-input>
                </div>
                <div class="form-group col-md-6 align-self-end d-flex">
                    <label class="form-check-label">
                        <form-checkbox
                            v-model="employee.is_photographer"
                            option_label="カメラマン"
                        ></form-checkbox>
                    </label>
                </div>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>ログインID</label>
                <form-input
                    v-model="employee.login_key"
                    :required="true"
                ></form-input>
            </div>
            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label>パスワード</label>
                    <form-input
                        v-if="visible"
                        v-model="employee.password"
                        pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,1000}$"
                    ></form-input>
                    <form-input-password
                        v-if="!visible"
                        v-model="employee.password"
                        pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,1000}$"
                    ></form-input-password>
                    <small class="text-muted">変更時のみ入力</small>
                    <br>
                    <small class="text-muted">大文字を含む半角英数字8文字以上</small>
                </div>
                <div class="form-group col-md-6 align-self-center">
                    <div class="form-check form-switch">
                        <input
                            v-model="visible"
                            type="checkbox"
                            class="form-check-input"
                            id="visibleCheckBox"
                            :true-value="true"
                            :false-value="false"
                        >
                        <label class="form-check-label" for="visibleCheckBox"><i class="bi bi-eye"></i></label>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>並び順</label>
                <form-input-number
                    v-model="employee.priority"
                    :required="true"
                    min="0"
                    max="9999"
                    step="1"
                ></form-input-number>
            </div>
            <label>権限</label>
            <div class="form-group col-md mb-4">
                <form-checkboxes
                    v-model="employee.roles"
                    :options="roles"
                ></form-checkboxes>
            </div>

            <div class="d-flex justify-content-between">
                <button-exec-update
                    size="btn-lg"
                ></button-exec-update>
                <button-exec-delete
                    size="btn-lg"
                    @click="$refs.confirm_remove.show()"
                ></button-exec-delete>
            </div>
        </form>
    </section>

    <confirm-dialog ref="confirm_remove" @ok="remove()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import ConfirmDialog from '@/components/tools/ConfirmDialog';
import Employee from '@/models/entities/employee';
import Role from '@/models/enums/employee/role';
import ButtonBack from '@/components/buttons/ButtonBack';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import FormInput from '@/components/forms/FormInput';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormInputPassword from '@/components/forms/FormInputPassword';
import FormCheckboxes from '@/components/forms/FormCheckboxes';
import FormCheckbox from '@/components/forms/FormCheckbox.vue';

export default {
    name: 'EmployeeEdit',
    components: {
        PageTitle,
        ConfirmDialog,
        ButtonBack,
        ButtonExecUpdate,
        ButtonExecDelete,
        FormInput,
        FormInputNumber,
        FormInputPassword,
        FormCheckboxes,
        FormCheckbox,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            employee: new Employee(),
            visible: false,
            roles: Role.options(),
            employee_id: parseInt(this.$route.params.id, 10),
        }
    },
    mounted() {
        this.fetchEmployee();
    },
    methods: {
        fetchEmployee() {
            this.startScreenLoading();

            this.$http.get(`/employees/${this.employee_id}`)
            .then(response => {
                this.employee = new Employee(response.data);
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        save() {
            this.startScreenLoading();
            this.$http.put(`/employees/${this.employee_id}`, this.employee)
            .then(() => {
                this.showMessage('編集しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        remove() {
            this.startScreenLoading();
            this.$http.delete(`/employees/${this.employee_id}`)
            .then(() => {
                this.showMessage('削除しました');
                this.$router.push({name: 'EmployeeList'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
